<template>
  <div class="pb-5 pt-6 px-4 justify-start items-center">
    <div v-if="!loadingConfirmTaskerDaily">
      <div
        v-if="getTaskerDailyConfirmations && getTaskerDailyConfirmations.length"
        class="pt-4"
      >
        <span class="text-primary font-medium">
          Tareo diario por tareadores
        </span>
        <br />
        <span
          v-for="confirmation in getTaskerDailyConfirmations"
          :key="confirmation.key"
        >
          Confirmación tareador:
          {{ confirmation.userEmail }} <br />
        </span>
      </div>
      <div v-else class="pt-4">
        <span class="text-primary font-medium"
          >Confirmación de tareo pendiente</span
        >
      </div>

      <div v-if="getTaskerDailyConfirmation" class="pt-4">
        <span class="text-primary font-medium">
          Tareo diario
          {{
            getTaskerDailyConfirmation.isConfirmed
              ? "confirmado"
              : "por confirmar"
          }}
        </span>
        <br />
        <span v-if="getTaskerDailyConfirmation.isConfirmed">
          Confirmación tareador:
          {{ getTaskerDailyConfirmation.userEmail || "" }} <br />
        </span>

        <button
          v-if="
            getTaskerDailyConfirmation &&
            getTaskerDailyConfirmation.isConfirmed === false
          "
          class="
            flex
            text-white
            border
            text-sm
            font-medium
            bg-primary
            border-primary
            py-2
            px-3
            my-auto
          "
          :disabled="loadingConfirmTaskerDaily"
          @click="onConfirmTaskerDaily"
        >
          <LoadingButtonIcon v-if="loadingConfirmTaskerDaily" /> Confirmar tareo
        </button>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-32 pt-10 pl-1">
      <h4 class="font-medium text-secondary text-xl">
        Cantidad de trabajadores tareados: {{ countUserWorked }}
      </h4>
    </div>

    <div class="mt-16">
      <ChartDepartureCategory :date-from="props.date" />
    </div>
    <div class="mt-16">
      <ChartDepartureCategoryWorkHour :date-from="props.date" />
    </div>
    <div class="mt-16">
      <VueHighcharts
        v-if="!refreshChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        :options="chartBarOptions"
      />
    </div>
    <div class="mt-16">
      <VueHighcharts
        v-if="!refreshChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        :options="chartOptions"
      />
    </div>
    <div class="mt-16">
      <VueHighcharts
        v-if="!refreshChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        :options="chartBarDepartureOptions"
      />
    </div>
    <div class="mt-16">
      <VueHighcharts
        v-if="!refreshChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        :options="chartDepartureOptions"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, watch, nextTick } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";
import ChartDepartureCategory from "../../components/ChartDepartureCategory.vue";
import ChartDepartureCategoryWorkHour from "../../components/ChartDepartureCategoryWorkHour.vue";

const props = defineProps({
  date: {
    type: String,
  },
});

const store = useStore();

const loadingData = ref(false);
const chartData = ref([]);
const chartDepartureData = ref([]);
const countUserWorked = ref(0);
const refreshChart = ref(false);
const loadingConfirmTaskerDaily = ref(false);

const getChartColors = computed(() => store.getters.getChartColors);
const getCategoryReport = computed(() => store.getters.getCategoryReport);
const getDepartureReport = computed(() => store.getters.getDepartureReport);
const getTaskerDailyConfirmation = computed(
  () => store.getters.getTaskerDailyConfirmation
);
const getTaskerDailyConfirmations = computed(
  () => store.getters.getTaskerDailyConfirmations
);

const chartOptions = computed(() => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: { text: "Cantidad por categoría" },
  colors: getChartColors.value,
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        connectorColor: "#777",
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
    },
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
      showInLegend: true,
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Cantidad trabajadores",
      //innerSize: "20%",
      data: chartData.value,
    },
  ],
}));

const chartBarOptions = computed(() => ({
  chart: {
    type: "column",
    options3d: {
      enabled: true,
      alpha: 45,
    },
  },
  colors: getChartColors.value,
  title: { text: "Cantidad de trabajadores por categoría" },
  yAxis: {
    title: {
      text: "Cantidad de trabajadores",
    },
  },
  xAxis: {
    type: "category",
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Trabajadores",
      dataSorting: {
        enabled: true,
      },
      data: chartData.value,
      dataLabels: {
        enabled: true,
        // rotation: -90,
        color: "#FFFFFF",
        // align: "right",
        format: "{point.y}",
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
  ],
}));
const chartDepartureOptions = computed(() => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  colors: getChartColors.value,
  title: { text: "Cantidad de trabajadores por partidas" },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
      showInLegend: true,
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Cantidad trabajadores",
      //innerSize: "20%",
      data: chartDepartureData.value,
    },
  ],
}));
const chartBarDepartureOptions = computed(() => ({
  chart: {
    type: "column",
    options3d: {
      enabled: true,
      alpha: 45,
    },
  },
  colors: getChartColors.value,
  title: { text: "Cantidad de trabajadores por partida" },
  yAxis: {
    title: {
      text: "Cantidad de trabajadores",
    },
  },
  xAxis: {
    type: "category",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: "Trabajadores",
      dataSorting: {
        enabled: true,
      },
      data: chartDepartureData.value,
      dataLabels: {
        enabled: true,
        // rotation: -90,
        color: "#FFFFFF",
        // align: "right",
        format: "{point.y}",
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
  ],
}));

const getShowMenu = computed(() => store.getters.getShowMenu);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getCategoryReport.value,
  (currentValue) => {
    chartData.value = currentValue?.map((v) => [v.name, v.userWorked]) || [];
  }
);
watch(
  () => getDepartureReport.value,
  (currentValue) => {
    chartDepartureData.value =
      currentValue?.map((v) => [v.name, v.userWorked]) || [];
  }
);

const loadData = async () => {
  try {
    store.dispatch("resetDailyReport");
    store.dispatch("resetWeekReport");

    chartData.value = [];
    loadingData.value = true;

    const response = await store.dispatch("loadUserWorkedReport", {
      dateFrom: props.date,
    });

    countUserWorked.value = response?.userWorked || 0;

    await store.dispatch("loadCategoryReport", {
      dateFrom: props.date,
    });

    await store.dispatch("loadWeekReport", {
      dateFrom: props.date,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingData.value = true;
  }
};

const loadTaskerDailyConfirmations = async () => {
  try {
    loadingConfirmTaskerDaily.value = true;

    await store.dispatch("findAllTaskerDailyConfirmations", {
      date: props.date,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingConfirmTaskerDaily.value = false;
  }
};

const loadSecondTaskerDailyConfirmation = async () => {
  try {
    loadingConfirmTaskerDaily.value = true;

    await store.dispatch("findTaskerDailyConfirmation", {
      date: props.date,
      type: "Second",
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingConfirmTaskerDaily.value = false;
  }
};

watch(
  () => props.date,
  () => {
    loadData();

    loadTaskerDailyConfirmations();
    loadSecondTaskerDailyConfirmation();
  }
);

const init = async () => {
  await loadData();

  loadTaskerDailyConfirmations();
  loadSecondTaskerDailyConfirmation();
};

init();

const onConfirmTaskerDaily = async () => {
  try {
    loadingConfirmTaskerDaily.value = true;

    await store.dispatch("confirmSecondTaskerDaily", {
      date: props.date,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingConfirmTaskerDaily.value = false;
  }
};
</script>
