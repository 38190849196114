<template>
  <Modal :open="open" width="w-1/3" @update:open="onClose">
    <template #body>
      <div class="p-4">
        <h3 class="text-lg font-bold">Enviar a CA</h3>
        <p class="mb-0">
          ¿Estás seguro de enviar el reporte de la semana del
          <strong>{{ dateFrom }}</strong> al <strong>{{ dateTo }}</strong> a CA?
        </p>
      </div>
    </template>

    <template #buttons>
      <button
        class="
          rounded
          bg-primary
          py-2
          px-5
          ml-2
          text-white
          font-medium
          text-sm
          flex
        "
        type="submit"
        :disabled="loading"
        @click="onSendToCA"
      >
        <LoadingButtonIcon v-if="loading" />
        Enviar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  dateFrom: {
    type: String,
    required: true,
  },
  dateTo: {
    type: String,
    required: true,
  },
});

const store = useStore();

const loading = ref(false);

const init = () => {};

init();

const onSendToCA = async () => {
  try {
    loading.value = true;
    // Do something
    console.log("Send to CA", props);
    await store.dispatch("syncTaskerWeeklyToCASistemas", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });

    onClose();
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const onClose = () => {
  emit("update:open", false);
};
</script>
