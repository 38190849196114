<template>
  <div>
    <VueHighcharts
      v-if="showChart && !refreshChart"
      type="chart"
      :redrawOnUpdate="true"
      :oneToOneUpdate="true"
      :animateOnUpdate="true"
      :options="chartOptions"
    />
  </div>
  <div class="mt-10">
    <VueHighcharts
      v-if="showChart && !refreshChart"
      type="chart"
      :redrawOnUpdate="true"
      :oneToOneUpdate="true"
      :animateOnUpdate="true"
      :options="chartWeeklyOptions"
    />
  </div>
  <div class="mt-10">
    <VueHighcharts
      v-if="showChart && !refreshChart"
      type="chart"
      :redrawOnUpdate="true"
      :oneToOneUpdate="true"
      :animateOnUpdate="true"
      :options="chartWeeklyBarOptions"
    />
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, watch } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";

const emit = defineEmits(["update:loading"]);

const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
  departuresKey: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const showChart = ref(false);
const chartData = ref([]);
const chartDataWeekly = ref([]);
const chartDataWeeklyCategories = ref([]);
const refreshChart = ref(false);

const getChartColors = computed(() => store.getters.getChartColors);
const getAccumulatedHourWorkedReport = computed(
  () => store.getters.getAccumulatedHourWorkedReport
);
const getAccumulatedReport = computed(() => store.getters.getAccumulatedReport);

const chartOptions = computed(() => ({
  chart: { type: "line" },
  title: { text: "HH Acumuladas (Diario)" },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%e. %b %Y",
      month: "%e. %b %Y",
      year: "%b %Y",
    },
  },

  yAxis: { title: { text: "HH Acumuladas" } },
  credits: { enabled: false },
  series: chartData.value,
  tooltip: {
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: "{point.x:%e. %b %Y}: {point.y:.2f} HH",
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
    series: {
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    },
  },
  colors: getChartColors.value,
}));
const chartWeeklyOptions = computed(() => ({
  chart: { type: "line" },
  title: { text: "HH Acumuladas (Semanal)" },
  xAxis: {
    categories: chartDataWeeklyCategories.value,
    title: { text: "Semanas" },
  },
  // xAxis: {
  //   type: "datetime",
  //   dateTimeLabelFormats: {
  //     day: "%e. %b %Y",
  //     month: "%e. %b %Y",
  //     year: "%b %Y",
  //   },
  // },

  yAxis: { title: { text: "HH Acumuladas" } },
  credits: { enabled: false },
  series: chartDataWeekly.value,
  tooltip: {
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: "{point.name}: {point.y:.2f} HH",
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    line: {
      dataLabels: {
        enabled: true,
      },
      enableMouseTracking: true,
    },
    series: {
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    },
  },
  colors: getChartColors.value,
}));
const chartWeeklyBarOptions = computed(() => ({
  chart: { type: "column" },
  title: { text: "HH Acumuladas (Semanal)" },
  xAxis: {
    categories: chartDataWeeklyCategories.value,
    title: { text: "Semanas" },
  },
  yAxis: { title: { text: "HH Acumuladas" } },
  credits: { enabled: false },
  series: chartDataWeekly.value,
  tooltip: {
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: "{point.name}: {point.y:.2f} HH",
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    series: {
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    },
  },
  colors: getChartColors.value,
}));

const getShowMenu = computed(() => store.getters.getShowMenu);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getAccumulatedHourWorkedReport.value,
  (currentValue) => {
    let data = [];
    let dataWeekly = [];

    if (currentValue && currentValue.length) {
      for (let value of currentValue) {
        data.push(
          {
            name: "HH Real Acum (tareo)",
            data: value.detail
              .filter((r) => r.hoursWorked)
              .map((r) => [Date.parse(r.date), r.hoursWorked]),
          },
          {
            name: "HH Presupuesto Acum",
            data: value.detail.map((r) => [Date.parse(r.date), r.budgetHours]),
          },
          {
            name: "HH Meta Acum",
            data: value.detail.map((r) => [Date.parse(r.date), r.goalHours]),
          }
        );

        dataWeekly.push(
          {
            name: "HH Real Acum (tareo)",
            data: value.resume
              .filter((r) => r.hoursWorked)
              .map((r) => [r.weekName, r.hoursWorked]),
          },
          {
            name: "HH Meta Acum",
            data: value.resume.map((r) => [r.weekName, r.goalHours]),
          },
          {
            name: "HH Presupuesto Acum",
            data: value.resume.map((r) => [r.weekName, r.budgetHours]),
          }
        );
      }
    }

    showChart.value = false;
    nextTick(() => {
      showChart.value = true;
      chartData.value = data;
      chartDataWeekly.value = dataWeekly;
      chartDataWeeklyCategories.value = currentValue?.length
        ? currentValue[0].resume.map((r) => {
            var d = new Date(r.initialDate);
            var dayNum = d.getUTCDay() || 7;
            d.setUTCDate(d.getUTCDate() + 4 - dayNum);
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            return `Semana ${Math.ceil(
              ((d - yearStart) / 86400000 + 1) / 7
            )} (${r.initialDate.split("-").reverse().join("/")})`;
          })
        : [];
    });
  },
  { deep: true }
);

watch(
  () => getAccumulatedReport.value,
  (currentValue) => {
    if (getAccumulatedHourWorkedReport.value !== null) return;

    let data = [];
    let dataWeekly = [];

    if (currentValue && currentValue.length) {
      for (let value of currentValue) {
        value.resume.forEach((r) => {
          let currentDate = new Date(r.date);
          let dayOfWeek = currentDate.getUTCDay();
          let daysToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
          currentDate.setUTCDate(currentDate.getUTCDate() + daysToMonday);
          r.initialDate = currentDate.toISOString().split("T")[0];
        });

        const result = Object.values(
          value.resume.reduce((acc, item) => {
            if (!acc[item.initialDate]) {
              acc[item.initialDate] = {
                initialDate: item.initialDate,
                hoursWorked: 0,
              };
            }
            acc[item.initialDate].hoursWorked += item.hoursWorked;
            return acc;
          }, {})
        );

        let accumulatedHours = 0;

        data.push({
          name: "HH Real Acum (tareo)",
          data: value.resume.map((r) => {
            accumulatedHours += r.hoursWorked;
            return [Date.parse(r.date), accumulatedHours];
          }),
        });

        accumulatedHours = 0;

        dataWeekly.push({
          name: "HH Real Acum (tareo)",
          data: result.map((r) => {
            accumulatedHours += r.hoursWorked;
            return [r.initialDate, accumulatedHours];
          }),
        });
      }
    }

    showChart.value = false;
    nextTick(() => {
      showChart.value = true;
      chartData.value = data;
      chartDataWeekly.value = dataWeekly;
      chartDataWeeklyCategories.value = currentValue?.length
        ? [
            ...new Set(
              currentValue[0].resume.map((r) => {
                var d = new Date(r.initialDate);
                var dayNum = d.getUTCDay() || 7;
                d.setUTCDate(d.getUTCDate() + 4 - dayNum);
                var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
                return `Semana ${Math.ceil(
                  ((d - yearStart) / 86400000 + 1) / 7
                )} (${r.initialDate.split("-").reverse().join("/")})`;
              })
            ),
          ]
        : [];
    });
  },
  { deep: true }
);

const loadData = async () => {
  try {
    emit("update:loading", true);

    store.dispatch("resetAccumutaledHourWorkedReport");

    const filter = {
      dateFrom: props.dateFrom,
      departuresKey: props.departuresKey || [],
    };
    if (props.dateTo) {
      filter.dateTo = props.dateTo;
    }

    await store.dispatch("loadAccumulatedHourWorkedReport", filter);
  } catch (error) {
    console.error(error);
  } finally {
    emit("update:loading", false);
  }
};

watch(
  () => props.dateFrom,
  () => {
    loadData();
  }
);

watch(
  () => props.departuresKey,
  () => loadData(),
  { deep: true }
);

const init = async () => {
  await loadData();
};

init();
</script>
