<template>
  <div>
    <VueHighcharts
      v-if="chartDepartureCost && !refreshChart"
      type="chart"
      :redrawOnUpdate="true"
      :oneToOneUpdate="true"
      :animateOnUpdate="true"
      :options="chartBarDepartureCategoryCostOptions"
    />
  </div>
  <div class="mt-16">
    <VueHighcharts
      v-if="chartDepartureCost && chartDepartureBudget && !refreshChart"
      type="chart"
      :redrawOnUpdate="true"
      :oneToOneUpdate="true"
      :animateOnUpdate="true"
      :options="chartBarDepartureBudgetCategoryCostOptions"
    />
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watch, nextTick } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";

const emit = defineEmits(["update:loading"]);

const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
  departuresKey: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const chartDepartureCost = ref([]);
const chartDepartureBudget = ref([]);
const refreshChart = ref(false);

const getChartColors = computed(() => store.getters.getChartColors);
const getDepartureCategoryCostReport = computed(
  () => store.getters.getDepartureCategoryCostWorkHourReport
);

const chartBarDepartureCategoryCostOptions = computed(() => {
  return {
    colors: getChartColors.value,
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    title: {
      text: "Costo por partida",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "S/.",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} soles</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Costo real",
        data: chartDepartureCost.value,
        // dataSorting: {
        //   enabled: true,
        //   matchByName: true
        // },
        dataLabels: {
          enabled: true,
          // rotation: -90,
          color: "#FFFFFF",
          // align: "right",
          format: "S/.{point.y:.1f}",
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
    ],
  };
});

const chartBarDepartureBudgetCategoryCostOptions = computed(() => {
  return {
    colors: getChartColors.value,
    chart: {
      type: "column",
    },
    title: {
      text: "Costo Partida Real vs Costo Partida Presupuesto",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      min: 0,
      title: {
        text: "S/.",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} soles</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Costo real",
        data: chartDepartureCost.value,
        // dataSorting: {
        //   enabled: true,
        //   matchByName: true
        // },
        dataLabels: {
          enabled: true,
          // rotation: -90,
          color: "#FFFFFF",
          // align: "right",
          format: "S/.{point.y:.1f}",
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      {
        name: "Costo presupuesto",
        data: chartDepartureBudget.value,
        dataLabels: {
          enabled: true,
          // rotation: -90,
          color: "#FFFFFF",
          // align: "right",
          format: "S/.{point.y:.1f}",
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
    ],
  };
});

const getShowMenu = computed(() => store.getters.getShowMenu);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getDepartureCategoryCostReport.value,
  (currentValue) => {
    let groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        rv[x[key]] = [
          x.departureBudget,
          (rv[x[key]] ? rv[x[key]][1] : 0) + x.unitCostTotal,
        ];
        return rv;
      }, {});
    };

    const departures = groupBy(currentValue, "name");
    let departureBudget = [];
    let departureCost = [];

    for (const d in departures) {
      departureBudget.push([d, departures[d][0]]);
      departureCost.push([d, departures[d][1]]);
    }

    chartDepartureCost.value = departureCost;
    chartDepartureBudget.value = departureBudget;
  }
);

const loadData = async () => {
  try {
    emit("update:loading", true);

    const filter = {
      dateFrom: props.dateFrom,
      departuresKey: props.departuresKey || [],
    };
    if (props.dateTo) filter.dateTo = props.dateTo;

    await store.dispatch("loadDepartureCategoryCostWorkHourReport", filter);
  } catch (error) {
    console.error(error);
  } finally {
    emit("update:loading", false);
  }
};

watch(
  () => props.dateFrom,
  () => loadData()
);

watch(
  () => props.departuresKey,
  () => loadData(),
  { deep: true }
);

const init = async () => {
  await loadData();
};

init();
</script>
